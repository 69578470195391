@import "../../lib/colors";

.shopifyThemeBackup {
  &__item {
    display: flex;
    padding: 20px;
    width: calc(100% - 40px);
    align-items: center;
    justify-content: space-between;
    list-style: none;
    border-bottom: solid 1px #eee;

    &__left {
      display: flex;
      width: 70%;
      align-items: center;

      &--input {
        margin: 0 8px;
      }

      &__backup {
        &--memo {
          margin-bottom: 8px;
        }

        &--pencil {
          margin-left: 8px;
          cursor: pointer;

          &--disabled {
            color: #eee;
            cursor: not-allowed;
          }
        }

        &--input {
          font-size: 16px;
        }

        &--save {
          margin-left: 2px;
          font-size: 16px;
          font-weight: medium;
          background-color: $secondary;
          color: white;
          border: none;
          border-radius: 2px;
          cursor: pointer;

            &--disabled {
              background-color: #eee;
              color: #aaa;
              cursor: not-allowed;
            }
        }

      }

      &__theme {
        margin-left: 20px;
        width: 50%;
      }
    }

    &__right {
      display: flex;
      padding: 0 8px;
      width: 16%;
      gap: 8px;
    }
  }
}
