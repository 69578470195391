@import "../../lib/colors";

.button {
  color: white;
  width: 100%;
  vertical-align: middle;
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  &--primary {
    background-color: $primary;
    height: 36px;
    line-height: 36px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  &--secondary {
    background-color: $secondary;
    height: 32px;
    line-height: 32px;
  }
  &--loading {
    background-color: #1786d0;
    height: 29px;
    line-height: 29px;
    border-radius: 3px 3px 0 0;
    & > span {
      border-radius: 0 0 3px 3px;
    }
  }
  &--warning {
    background-color: #ccc;
    height: 32px;
    line-height: 32px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: #ae282f;
    }
  }

  &--disabled {
    background-color: #eee;
    color: #aaa;
    height: 32px;
    line-height: 32px;
    cursor: not-allowed;
  }
}
