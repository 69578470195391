.onBackup {
  width: 100%;
  margin: 0 auto;
  padding: 40px 0;

  &__popup {
    margin: 0 auto;
    width: 360px;
    background-color: white;
    border: solid 1px #666;
    border-radius: 3px;
    padding: 16px 32px;

    &__title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 12px;
    }
    &__small {
      display: block;
      margin-bottom: 20px;
    }
    &__theme {
      line-height: 32px;
      vertical-align: middle;
      display: flex;
      &__name {
        margin: 0 0 0 8px;
      }
    }
  }
}
