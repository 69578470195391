.listBackups {
  width: calc(90% + 48px);
  max-width: 880px;
  margin: 0 auto;
  padding: 0 0 64px;
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    margin-bottom: 12px;
    &__title {
      font-size: 24px;
      font-weight: bold;
      line-height: 36px;
      vertical-align: middle;
    }
    &__button {
      margin: 12px 0 0 0;
      font-size: 14px;
      line-height: 24px;
      width: 172px;
    }
  }
  &__table {
    border-radius: 4px;
    border: solid 1px #ccc;
    width: 100%;
    border-spacing: 0;
    &__tr {
      &--header {
        height: 36px;
      }
      &__td {
        border-top: solid 1px #ddd;
        border-right: dashed 1px #ddd;
        &--memo {
          width: 35%;
          vertical-align: top;
        }
        &--theme {
          border-right: none;
          width: 35%;
          font-size: 16px;
        }
        &--rewind,
        &--bulkRewind {
          width: 112px;
          padding: 4px 0;
        }
        &--bulkRewind {
          border-right: none;
        }
        &__checkbox {
          display: block;
          margin: 0 auto;
        }
        &__memo {
          padding: 8px;
          &__text {
            font-size: 16px;
            margin-bottom: 8px;
          }
          &__datetime {
            font-size: 14px;
          }
        }
        &__button {
          width: 96px;
          height: 32px;
          margin: 0 auto;
        }
      }
    }
  }

  &__popup {
    margin: 40px auto;
    width: 360px;
    background-color: white;
    border: solid 1px #666;
    border-radius: 3px;
    padding: 16px 32px;

    &__title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 12px;
    }
    &__small {
      display: block;
      margin-bottom: 20px;
    }
    &__theme {
      line-height: 32px;
      vertical-align: middle;
      display: flex;
      &__name {
        margin: 0 0 0 8px;
      }
    }
  }
}
